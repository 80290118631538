import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Bliki from "../components/Bliki"
import SEO from "../components/SEO"

export const query = graphql`
  query($slug: String!) {
    bliki: mdx(
      frontmatter: { slug: { eq: $slug } }
      fields: { show: { eq: true } }
    ) {
      frontmatter {
        title
        slug
        last_updated_on(formatString: "MMM DD, YYYY")
        tags
        type
        links {
          title
          slug
        }
      }
      body
      excerpt
    }
  }
`

const blikiTemplate = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO
        title={data.bliki.frontmatter.title}
        description={data.bliki.excerpt}
        url={data.bliki.frontmatter.slug}
        isArticle
      />
      <Bliki
        title={data.bliki.frontmatter.title}
        slug={data.bliki.frontmatter.slug}
        tags={data.bliki.frontmatter.tags}
        type={data.bliki.frontmatter.type}
        last_updated_on={data.bliki.frontmatter.last_updated_on}
        body={data.bliki.body}
        links={data.bliki.frontmatter.links}
      />
    </Layout>
  )
}

export default blikiTemplate
