import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Tag from "./Tag"
import { Link } from "gatsby"

const Bliki = ({ title, slug, tags, type, last_updated_on, body, links }) => {
  return (
    <article className="container sm:max-w-screen-sm mx-auto mt-10 w-11/12 sm:w-9/12 pt-24">
      <div className="flex flex-col">
        <div className="flex">
          <div className="w-2/5"></div>
          <button className="py-0.5 px-4 mx-20 my-4 rounded-full bg-primary-500 text-gray-100 text-xs btn-primary uppercase">
            {type}
          </button>
          <div className="w-2/5"></div>
        </div>

        <header>
          <h1 className="mx-2 text-center">{title}</h1>
        </header>

        <hr className="w-1/6 mx-auto m-4 border-none h-2 text-primary-400 bg-primary-400 rounded" />

        <div className="flex flex-row justify-center flex-wrap mx-2 mt-4">
          {tags &&
            tags.map((item, index) => {
              return <Tag key={index} name={item} />
            })}
        </div>

        <div className="flex flex-row mt-4 justify-center">
          <span className="text-gray-400 font-light pb-4">
            Last Updated on {last_updated_on}
          </span>
        </div>
        <div className="prose mx-auto my-4 text-left text-lg w-auto">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <hr className="mt-4 border-2 rounded w-1/3 mx-auto border-primary-400" />
        <div className="prose">
          {links && (
            <div>
              <h2>Related:</h2>
              <ul className="space-y-3">
                {links.map((item, index) => {
                  return (
                    <li key={index} className="ml-4">
                      <Link className="text-lg" to={`/${item.slug}`}>
                        {item.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

export default Bliki
